import React from "react";

const LocalBusinessSchema = () => (
  <React.Fragment>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "The Twin Towers Hotel",
            "image": "https://www.hoteltwintowers.com/images/home-banner.png",
            "@id": "",
            "url": "https://hoteltwintowers.com/",
            "telephone": "+91 07807885113",
            "priceRange": "$$$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "NH 05, New kufri, Galu, Hills",
              "addressLocality": "Shimla",
              "postalCode": "171209",
              "addressCountry": "IN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 31.0891785,
              "longitude": 77.28672519999999
            },
            "sameAs": [
              "https://www.facebook.com/twintower.hotel/",
              "https://www.instagram.com/hotel_twintowers/",
              "https://www.linkedin.com/in/the-twin-towers-hotel/"
            ]
          }
        )
      }}
    />
  </React.Fragment>
);

export default LocalBusinessSchema;