import React from "react";

const HotelSchema = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "http://schema.org",
                        "@type": "Hotel",
                        "name": "The Twin Towers Hotel",
                        "alternateName": "If Known by Another Shorter Name",
                        "url": "https://hoteltwintowers.com/",
                        "logo": "https://www.hoteltwintowers.com/icons/header-logo.svg",
                        "image": "https://www.hoteltwintowers.com/images/home-banner.png",
                        "description": "One of the best hotel in Kufri, it is located in the heart of town, amid the Himalayan foothills. The location is a beautiful retreat in a mountainous neighborhood, offering visitors a tranquil, calm, and pleasant atmosphere for a soul-filling holiday, and being so near to nature offers a choice of activities that will nourish your spirit. It will undoubtedly assist you in unwinding while you participate in the most exciting activities with your loved ones at and around the resort, since it is a great site for fun activities such as adventure sports, walks and trails, and so on.",
                        "sameAs": [
                            "https://www.facebook.com/twintower.hotel/",
                            "https://www.instagram.com/hotel_twintowers/",
                            "https://www.linkedin.com/in/the-twin-towers-hotel/"
                        ],
                        "telephone": "+91 07807885113",
                        "email": "info@hoteltwintowers.com",
                        "hasMap": "https://goo.gl/maps/GzserDhdgJJFburx5",
                        "checkinTime": "14:00",
                        "checkoutTime": "12:00",
                        "additionalType": "",
                        "areaServed": "",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "NH 05, New kufri,",
                            "addressLocality": "Galu, Hills,",
                            "addressRegion": "Shimla",
                            "postalCode": "171209",
                            "addressCountry": "India"
                        },
                        "priceRange": "$$$",
                        "starRating": {
                            "@type": "http://schema.org/Rating",
                            "ratingValue": "5"
                        }
                    }
                )
            }}
        />
    </React.Fragment>
);
export default HotelSchema;