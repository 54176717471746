
import React, { useState, useRef, useEffect } from "react";

const FAQs = (props) => {
    const [openIndex, setOpenIndex] = useState(0);
    const contentRefs = useRef([]);
    const faqs = props?.faqsData

    useEffect(() => {
        contentRefs.current = contentRefs.current.slice(0, faqs.length);
    }, [faqs]);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={`destination_faqs row justify-content-center`}>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                {faqs.map((item, index) => {
                    return (
                        <div className="accordion" key={index}>
                            <div className="accordion__title" onClick={() => toggleAccordion(index)}>
                                <h4> {item.accordionQuestion}</h4>
                                <div className="accordion_btn">
                                    <button className={`arrow_btn ${openIndex === index ? 'rotate' : ''}`} aria-label="Accordion Button">
                                        {openIndex === index ?
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M232-444v-72h496v72H232Z" /></svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                            </svg>
                                        }
                                    </button>
                                </div>
                            </div>
                            <div
                                ref={el => contentRefs.current[index] = el}
                                className={`accordion__content ${openIndex === index ? "accordion__content--open" : ""}`}
                                style={
                                    openIndex === index
                                        ? { maxHeight: contentRefs.current[index] ? contentRefs.current[index].scrollHeight : 'none' }
                                        : { maxHeight: 0 }
                                }
                            >
                                <p>{item.accordionAnswer}</p>
                            </div>
                        </div >
                    );
                })}
            </div>
        </div>
    );
};
export default React.memo(FAQs);
