import Link from 'next/link';
import React from 'react';
import styles from './Reusable.module.scss';
import Image from 'next/image';

const ContactLink = React.memo(({ phoneNumber, iconSrc }) => (
  <Link href={`tel:${phoneNumber}`} className={styles.room_contact_no}>
    <div className='icon_con'>
      {/* <Image src={iconSrc} height={21} width={21} alt="contact-icon" /> */}
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1A1A1A"><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" /></svg>
    </div>
    <p className='m-0'>{phoneNumber}</p>
  </Link>
));

export default ContactLink;