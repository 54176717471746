import React from "react";

const LodgingReservationSchema = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "http://schema.org",
                        "@type": "LodgingReservation",
                        "reservationNumber": "",
                        "reservationStatus": "http://schema.org/Confirmed",
                        "underName": {
                            "@type": "Person",
                            "name": "John Smith"
                        },
                        "reservationFor": {
                            "@type": "LodgingBusiness",
                            "name": "The Twin Towers Hotel",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "NH 05, New kufri, Galu, Hills",
                                "addressLocality": "Shimla",
                                "addressRegion": "Indian Himalayan Region",
                                "postalCode": "171209",
                                "addressCountry": "IN"
                            },
                            "telephone": "07807885113"
                        },
                        "checkinDate": "",
                        "checkoutDate": ""
                    }
                )
            }}
        />
    </React.Fragment>
);
export default LodgingReservationSchema;