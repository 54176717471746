import styles from "./Reusable.module.scss";
import Link from 'next/link';
import Image from 'next/image';
import React from "react";

const AboutSection = () => {
    return (
        <section className="common_padding">
            <div className="container">
                <div className={`${styles.about_sec_main} row align-items-center`}>
                    <div className={`${styles.about_sec_main_left} col-lg-5 col-md-12 col-sm-12`}>
                        <div className="common_title">
                            <span>Luxury Hotel In Kufri, Shimla</span>
                            <h1 className="text-capitalize">
                                The Twin Towers Hotel
                            </h1>
                            <p>One of the best Luxury Hotel in Kufri, The Twin Towers Hotel, is located in the heart of town, amid the Himalayan foothills. It offers a beautiful retreat in a mountainous neighborhood, providing visitors with a tranquil, calm, and pleasant atmosphere for a soul-filling holiday. Being so near to nature offers a choice of activities that will nourish your spirit. It will undoubtedly assist you in unwinding while you participate in the most exciting activities with your loved ones at and around the resort. This is the best hotel in Shimla, Kufri for fun activities such as adventure sports, walks, and trails. Whether you're looking for an affordable hotel in Shimla or a budget hotel in Shimla, The Twin Towers Hotel is the perfect choice for your stay. Book your stay at one of the top 4-star hotels in Kufri with online hotel booking in Shimla for a memorable experience in Himachal.</p>
                            <Link href="/about-us" className="secound_common_btn">About Us</Link>
                        </div>
                    </div>
                    <div className={`${styles.about_sec_main_right} col-lg-7 col-md-12 col-sm-12`}>
                        <Image
                            src="/images/about-section-img.png"
                            width={758}
                            height={408}
                            alt="Hotel Twin Tower"
                            priority
                        />
                    </div>
                    <div className={styles.about_contact_no}>
                        <Link href="tel:9318192605">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1A1A1A">
                                    <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                                </svg>
                            </span>
                            <p>+91 93181-92605</p>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default React.memo(AboutSection);

// <div className={`${styles.img_container} ${styles.first_img}`}>
// <Image
//     src="/images/about-sec-1.webp"
//     alt="Hotel Twin Tower"
//     width={280}
//     height={328}
//     sizes="(max-width: 767px) 200px, (max-width: 1024px) 280px, 328px"
//     placeholder="blur"
//     blurDataURL="https://www.success.com/wp-content/uploads/2023/12/istockphoto-1147544807-612x612-1.jpg"
// />
// </div>
// <div className={`${styles.img_container} ${styles.centered_img}`}>
// <Image
//     src="/images/about-sec-2.webp"
//     alt="Hotel Twin Tower Restaurant Image"
//     width={280}
//     height={328}
//     sizes="(max-width: 767px) 200px, (max-width: 1024px) 280px, 328px"
//     placeholder="blur"
//     blurDataURL="https://www.success.com/wp-content/uploads/2023/12/istockphoto-1147544807-612x612-1.jpg"
// />
// </div>
// <div className={`${styles.img_container} ${styles.last_img}`}>
// <Image
//     src="/images/about-sec-3.webp"
//     alt="Hotel Twin Tower Room Image"
//     width={280}
//     height={328}
//     sizes="(max-width: 767px) 200px, (max-width: 1024px) 280px, 328px"
//     placeholder="blur"
//     blurDataURL="https://www.success.com/wp-content/uploads/2023/12/istockphoto-1147544807-612x612-1.jpg"
// />
// </div>